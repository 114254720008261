.header-container {
    background: url(../../image/background.svg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 315px;
    text-align: center;
    
}

.header-profile-wrapper {
    justify-content: center;
}

.header-profile-logo .logo {
    width: 105px;
    height: 43px;
    opacity: 1;
    /* display: block; */
    margin-left: auto;
    margin-right: auto;
}

.header-profile-picture .bitmap-image {
    width: 80px;
    height: 80px;
    opacity: 1;
    /* display: block; */
    border: 3px solid #EAAA00;
    border-radius: 50%;
    margin: 15px;
}

.header-profile-name .header-profile-name-title {
    font-weight: bold;
    line-height: 27px;
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    text-transform: uppercase;
}

.header-profile-login {
    color: #FFFFFF;
    opacity: 0.7;
    letter-spacing: 0px;
    line-height: 28px;
    font-size: 16px;
}

.header-profile-login-name {
    line-height: 28px;
    color: #ffffff;
    opacity: 1;
    letter-spacing: 0px;
    font-size: 16px;
}

.header-profile-telephone {
    color: #FFFFFF;
    opacity: 0.7;
    letter-spacing: 0px;
    line-height: 28px;
    font-size: 16px;
}

.header-profile-telephone-title {
    line-height: 28px;
    color: #ffffff;
    opacity: 1;
    letter-spacing: 0px;
    font-size: 16px;
}

.header-profile-status-div{
    background-color: #fdd26e;
    width: max-content;
    margin: auto;
    padding: 0 1%
}

.header-profile-status {
    color: #FFFFFF;
    opacity: 0.7;
    letter-spacing: 0px;
    line-height: 28px;
    font-size: 22px;
    color: red;
    font-weight: 600;
}

.header-profile-status-title {
    line-height: 28px;
    color: #ffffff;
    opacity: 1;
    letter-spacing: 0px;
    font-size: 22px;
    color: red;
    font-weight: bold;
    text-transform: uppercase;
}