.task-list-container{
    width: auto;
    height: auto;
    opacity: 1;
    box-shadow: 2px 2px 6px #00000029;
    border: 1px solid #EDEDED;
    border-radius: 5px;
    margin: 10px;
}
.task-list-select-row{
    padding: 15px;
    margin-bottom: 10px;
    width: 100%;
}
.task-list-title{
    text-align: left;
  height: auto;
  opacity: 1;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 1px;
}

.task-list-select{
    display: flex;
    cursor: pointer;
    border: 1px solid #EDEDED;
    opacity: 1;
    font-size: 16px;
    color: #143249;
    font-weight: 400;
    text-transform: capitalize;
    padding: 16px;
    margin-top: 10px;
    word-wrap: break-word;
}
.task-list-select-item{
    flex-grow: 1;
}

.task-list-select-option{
    cursor: pointer;
    border: 1px solid #EDEDED;
    opacity: 1;
    font-size: 16px;
    color: #143249;
    font-weight: 400;
    text-transform: capitalize;
    padding: 16px;
    min-height: 56px !important;
    height: auto !important;
    word-wrap: break-word;
}