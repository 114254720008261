@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:wght@400;500;600;700&family=Ubuntu:ital,wght@0,500;0,700;1,400&display=swap');

* {
    font-family: "Open Sans";
}

/* make scrollbar transparent */
/* ::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
} */

/* ----------------------------------------------------------------- */
.job-table-section{
    margin: 20px;
}
.main-table{
    border-radius: 5px !important;
}
.main-table-header{
    background-color: #143249 !important;
    color: white;
    height: 45px;
    border-radius: 5px !important;
   align-items: center;
   /* margin: 0; */
}
.main-table-header-title,.main-table-header-title-initial{
    background-color: #1B405C !important;
    border: none !important;
    font-size: 11px !important;
    font-weight: 600 !important;
}
.main-table-header-title,.main-table-header-title-initial:focus{
    box-shadow: 5px 5px 3px rgba(46, 46, 46, 0.62) !important;
}

/* ---------------------Parent Accordion------------------------------------------------ */
.parent-accordion{
   margin-left: -10px !important;
   margin-right: -10px !important;
   
}
.parent-accordion-header- .accordion-button {
    font-size: 16px !important;
    color: #0E0E0E !important;
    font-weight: 800 !important;
    word-break: break-all;
}
.parent-accordion-header-red .accordion-button {
    font-size: 16px !important;
    color: #0E0E0E !important;
    font-weight: 800 !important;
    background-color: #F7DBDC !important;
    word-break: break-all;
}

.parent-accordion-header-red .accordion-button:focus {
    box-shadow: none !important;
    word-break: break-all;
}

.parent-accordion-header-red .accordion-button::after {
    background-image: url(../image/arrowCircleDown.svg) !important;
    background-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    word-break: break-all;
}

.parent-accordion-header-red .accordion-button:not(.collapsed)::after {
    background-image: url(../image/arrowCircleRight.svg) !important;
    transform: none !important;
    word-break: break-all;
}

/* ------------------------ */

.parent-accordion-header-green .accordion-button {
    font-size: 16px !important;
    color: #0E0E0E !important;
    font-weight: 800 !important;
    background-color: #D5F6C0 !important;
    word-break: break-all;
}

.parent-accordion-header-green .accordion-button:focus {
    box-shadow: none !important;
    word-break: break-all;
}

.parent-accordion-header-green .accordion-button::after {
    background-image: url(../image/arrowCircleDown.svg) !important;
    background-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    word-break: break-all;
}

.parent-accordion-header-green .accordion-button:not(.collapsed)::after {
    background-image: url(../image/arrowCircleRight.svg) !important;
    transform: none !important;
    word-break: break-all;
}

/* ---------------- */

.parent-accordion-header-orange .accordion-button {
    font-size: 16px !important;
    color: #0E0E0E !important;
    font-weight: 800 !important;
    background-color: #eaa22c !important;
    word-break: break-all;
}

.parent-accordion-header-orange .accordion-button:focus {
    box-shadow: none !important;
    word-break: break-all;
}

.parent-accordion-header-orange .accordion-button::after {
    background-image: url(../image/arrowCircleDown.svg) !important;
    background-size: 22px !important;
    width: 22px !important;
    height: 22px !important;
    word-break: break-all;
}

.parent-accordion-header-orange .accordion-button:not(.collapsed)::after {
    background-image: url(../image/arrowCircleRight.svg) !important;
    transform: none !important;
    word-break: break-all;
}

/* --------------------------Child------------------------------------------------------------ */

.child-accordion-header- .accordion-button {
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 700 !important;
    padding-left: 39.67px !important;
    word-break: break-all;
}
.child-accordion-header-red .accordion-button {
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 700 !important;
    padding-left: 39.67px !important;
    background-color: #FBEDED !important;
    word-break: break-all;
}

.child-accordion-header-red .accordion-button:focus {
    box-shadow: none !important;
    word-break: break-all;
}

.child-accordion-header-red .accordion-button::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    word-break: break-all;
}

.child-accordion-header-red .accordion-button:not(.collapsed)::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    transform: rotate(-90deg);
    word-break: break-all;
}


/* -------------------- */

.child-accordion-header-green .accordion-button {
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 700 !important;
    padding-left: 39.67px !important;
    background-color: #EAFADF !important;
    word-break: break-all;
}

.child-accordion-header-green .accordion-button:focus {
    box-shadow: none !important;
    word-break: break-all;
}

.child-accordion-header-green .accordion-button::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    word-break: break-all;
}

.child-accordion-header-green .accordion-button:not(.collapsed)::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    transform: rotate(-90deg);
    word-break: break-all;
}

/* ---------------------- */


.child-accordion-header-orange .accordion-button {
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 700 !important;
    padding-left: 39.67px !important;
    background-color: #F5D196 !important;
    word-break: break-all;
}

.child-accordion-header-orange .accordion-button:focus {
    box-shadow: none !important;
    word-break: break-all;
}

.child-accordion-header-orange .accordion-button::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    word-break: break-all;
}

.child-accordion-header-orange .accordion-button:not(.collapsed)::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    transform: rotate(-90deg);
    word-break: break-all;
}

/* -------------------------- */

/* --------------------------Child------------------------------------------------------------ */

.task-child-accordion-header- .accordion-button {
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 700 !important;
    word-break: break-all;
}
.task-child-accordion-header-red .accordion-button {
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 700 !important;
    background-color: #FBEDED !important;
    word-break: break-all;
}

.task-child-accordion-header-red .accordion-button:focus {
    box-shadow: none !important;
    word-break: break-all;
}

.task-child-accordion-header-red .accordion-button::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    word-break: break-all;
}

.task-child-accordion-header-red .accordion-button:not(.collapsed)::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    transform: rotate(-90deg);
    word-break: break-all;
}


/* -------------------- */

.task-child-accordion-header-green .accordion-button {
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 700 !important;
    background-color: #EAFADF !important;
    word-break: break-all;
}

.task-child-accordion-header-green .accordion-button:focus {
    box-shadow: none !important;
    word-break: break-all;
}

.task-child-accordion-header-green .accordion-button::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    word-break: break-all;
}

.task-child-accordion-header-green .accordion-button:not(.collapsed)::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    transform: rotate(-90deg);
    word-break: break-all;
}

/* ---------------------- */


.task-child-accordion-header-orange .accordion-button {
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 700 !important;
    background-color: #F5D196 !important;
    word-break: break-all;
}

.task-child-accordion-header-orange .accordion-button:focus {
    box-shadow: none !important;
    word-break: break-all;
}

.task-child-accordion-header-orange .accordion-button::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    word-break: break-all;
}

.task-child-accordion-header-orange .accordion-button:not(.collapsed)::after {
    background-image: url(../image/angledown.svg) !important;
    background-size: 15px !important;
    transform: rotate(-90deg);
    word-break: break-all;
}

/* -------------------------- */

.associated-task-accordion{
    margin-left: -10px !important;
    margin-right: -10px !important;
}

/* -------------------------------------------------------------------------------------- */
.listOfRequirement-row-{
    padding: 10px;
    margin: 0px !important;
    border-bottom: 1px solid #e1e1e1;
}
.listOfRequirement-row-red{
    padding: 10px !important;
    background-color: #FDF6F6;
    margin: 0px !important;
    border-bottom: 1px solid #e1e1e1;
}
.listOfRequirement-row-green{
    padding: 10px !important;
    background-color: #F4FDEF;
    margin: 0px !important;
    border-bottom: 1px solid #e1e1e1;
}
.listOfRequirement-row-orange{
    padding: 10px !important;
    background-color: #FAE8CB;
    margin: 0px !important;
    border-bottom: 1px solid #e1e1e1;
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
    .main-table-header-title-initial{
        font-size: 10px !important;
    }
    .listOfRequirement-title{
        word-wrap: break-word !important;
    }
    .task-listOfRequirement-title{
        word-wrap: break-word !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 8192px) {
    .listOfRequirement-title{
        padding-left: 59.83px;
        padding-right: 10px;
        word-wrap: break-word !important;
    }
    .task-listOfRequirement-title{
        padding-left: 25px;
        padding-right: 10px;
        word-wrap: break-word !important;
    }
}

.listOfRequirement-title{
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 600 !important;
    font-style: italic !important;
}
.task-listOfRequirement-title{
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 600 !important;
    font-style: italic !important;
}
.listOfRequirement-completed-title{
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 600 !important;
    font-style: italic !important;
    
}
.listOfRequirement-expired-title{
    font-size: 14px !important;
    color: #0E0E0E !important;
    font-weight: 600 !important;
    font-style: italic !important;
    
}
.requirement-row{
    font-size: 14px !important;
    font-weight: 500;
    color: #0E0E0E !important;
    /* height: 45px; */
    padding: 10px;
    background-color: #E7F0FB;
    border-bottom: 1px solid #e1e1e1;
}

.requirement-row-title{
    word-wrap: break-word !important;
}
.accordion-header-text{
    height: auto;
    word-wrap: break-word !important;
    padding-right: 5px;
}

/* Spinner CSS */
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.loading-spinner {
    width: 100px;
    height: 100px;
    border: 10px solid #eaaa00; 
    border-top: 10px solid white; 
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000 !important
  }