.radio-tab-container {
  width: auto;
  height: 60px;
  display: grid;
  grid-template-columns: auto auto auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: 2px 2px 6px #00000029;
  border: 1px solid #EDEDED;
  border-radius: 5px;
  column-gap: 15px;
  margin: 10px;
}

.radio-tab-task-container {
  text-align: center;
  align-self: center;
  cursor: pointer;
}

.radio-tab-task-title {
  color: #143249;
  padding-left: 5px;
}

.radio-tab-task-input{
  width: 16px;
  height: 16px;
  cursor: pointer;
}