
.legends-item-container{
    text-align: center !important;
    padding: 10px;
    width:100%
}

.legend-dot-green{
    height: 15px;
  width: 15px;
  background-color: #D5F6C0;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.legend-dot-red{
    height: 15px;
  width: 15px;
  background-color: #F7DBDC;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.legend-dot-orange{
    height: 15px;
  width: 15px;
  background-color: #eaa22c;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.legend-dot-text{
    padding: 8px;
    font-size: 12px;
   
   
}
.legend-dot-note-text{
    padding: 10px;
    font-size: 12px;
    font-weight: 700;
}